.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.anexo_file {
  font-weight: 600;
  color: #000080;
  font-size: 18px;
  text-decoration: underline;
}

.newsItem {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header img {
  margin-right: 10px;
  width: 24px;
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  color: #000080;
}

/* Meta Info Section */
.metaInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-size: 0.9em;
  color: #666;
  margin-bottom: 15px;
}

.category {
  color: #666;
  font-size: 16px;
}

.date {
  color: #666;
}

.views {
  color: #666;
}

.imageWrapper {
  margin: 15px 0;
  text-align: center;
}

.imageWrapper img {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}

.content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;
}

.content p {
  margin-bottom: 15px;
}

/* Video Wrapper Styles */
.videoWrapper {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Seção de Compartilhamento */
.shareSection {
  margin-top: 20px;
  text-align: center;
}

.whatsappButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.whatsappButton img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.whatsappButton:hover {
  background-color: #1ebe57;
}
