.listItemPrimary{
color: var(--cor-sub-lista-navbar);

}
/* ul.map-site li.listItemPrimary p.textTopList{
    background-color: #036;
    color: #ffffff;
    padding: 7px;
}
ul.subList li.listItemPrimary p.textTopList{
    background-color: rgb(255, 255, 255);
    color: #000000;
    padding: 7px;
} */
.listItemPrimary p{
font-weight: 800;
color: var(--cor-sub-lista-navbar);

}
.subList{
margin:9;
}
.subItem{
margin-top:10px;
margin-bottom:10px;
color: var(--cor-sub-lista-navbar);

}
li.listItemPrimary{
margin:10px;
}
ul.subList li.listItemPrimary{
margin:0px;
color: var(--cor-sub-lista-navbar);

}
.textTopList{
color: var(--cor-sub-lista-navbar);

margin-top:10px;
margin-bottom:10px;


}

.link{
    color: var(--cor-sub-lista-navbar);

}

