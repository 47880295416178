.container_dispensa {
  display: flex;
  flex-direction: column;
  padding: 10px 60px;
  max-width: 100vw;
  overflow-x: hidden;
  margin-bottom: 50px;
}

.center_div_actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  border: none;
  color: #fff;
  padding: 4px 14px;
}

.btn_ativo {
  font-weight: 600;
  background-color: #000080;
}

.btn_desativado {
  background-color: #276ed4;
}

.container_item_codigo {
  display: flex;
  gap: 5px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 690px;
}

.tipo_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.item_id {
  color: #000080;
  font-weight: 600;
  padding: 4px 8px;
  border: 1px solid #000080;
  border-radius: 6px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-around;
}

.item_id:hover {
  color: #276ed4;
  border: 1px solid #276ed4;
}

table {
  border-collapse: collapse;
  min-width: 890px;
}

th,
td {
  border: 1px solid #ccc;
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

tr {
  padding: 4px 8px;
}

.btn {
  padding: 4px 8px;
  background-color: #ff9500;
  min-width: max-content;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 2px #00000015;
}

.btn:hover {
  box-shadow: 1px 1px 3px 2px #00000030;
  color: #fff;
}

.btn_baixar {
  padding: 4px 8px;
  border: 1px solid #276ed4;
  color: #276ed4;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 2px #00000015;
}

.btn_baixar:hover {
  box-shadow: 1px 1px 3px 2px #00000030;
}

@media screen and (max-width: 926px) {
  .container_dispensa {
    overflow-x: scroll;
    padding: 10px;
  }
}
