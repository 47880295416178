li {
  list-style: none;
}
.container-noticias {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.sub-header-news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.sub-header-news h2 {
  font-weight: 600;
  font-size: 24px;
}

.container-botoes {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.container-lista {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.noticia-item {
  margin-bottom: 0px;
  border-radius: 6px;
  background-color: #6e6e6e10;
  border: 1px solid #cccccc96;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 3px 14px;
}

.noticia-item.V {
  background-color: rgb(238, 247, 238);
}

.container-lista .title-td {
  max-width: 300px;
  min-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.views-td {
  color: #f5f5f5;
  padding: 2px 6px;
  border-radius: 6px;
}

.title-td {
  font-weight: 500;
}

.V .views-td {
  background-color: green;
}

.O .views-td {
  background-color: #dc3545;
}
