.container-noticia {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.sub-header-news {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.container-noticia main {
  max-width: 100%;
  border: 1px dotted #424242;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}
.container-noticia form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.form-input {
  display: flex;
  flex-direction: column;
}

.form-input input,
.form-input select {
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #222;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;
}

.input-file-container img {
  border-radius: 8px;
  height: 90px;
}

.input-file-container {
  min-width: 180px;
  max-width: fit-content;
  border: 2px dashed #000080;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 10px 40px;
  flex-wrap: wrap;
  padding: 5px 20px;
}

.custom-input-file {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.img-show-select {
  z-index: 3;
}

.form-input .input-fit {
  width: fit-content;
}

.form-input.checkbox {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px 10px;
}

/* checkbox */

.form-input.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

input[type="checkbox"] {
  display: none;
}

.checkmark {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  margin-left: 10px;
}

.checkmark:before {
  content: "";
  position: absolute;
  display: none;
}

input[type="checkbox"]:checked + .checkmark:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background: blue;
  border-radius: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked + .checkmark {
  border-color: blue;
}

/* Imagens secundarias */

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  border-radius: 8px;
  width: fit-content;
  gap: 5px 15px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.remover-atual {
  z-index: 10;
}

.icon-close-img-news {
  cursor: pointer;
}
