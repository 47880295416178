.gridFromOuvidoria1 input{
    border-radius: 15px;
    border: 1px solid var(--cor-border-ouvidoria);
}
.gridFromOuvidoria1 select{
    border-radius: 15px;
    border: 1px solid var(--cor-border-ouvidoria);
}
.columnForm H6{
    color: var(--cor-subTitle-ouvidoria);
}
::-webkit-input-placeholder{



    color: var(--cor-subTitle-ouvidoria);
    /* font-weight: bold; */
  }
  select {
    color: var(--cor-subTitle-ouvidoria);

  }



.gridFromOuvidoria1{
    display: grid;
    justify-items: stretch;
    justify-content: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 90% 90% 10%;
    padding-bottom:25%;
    column-gap: 50px;
    row-gap: 10px;
    max-width: 1200px;
    margin: 0 auto;
}
.columnForm{
    display: grid; 
    grid-template-columns: 100%;
    grid-template-rows: 15% 18% 18% 18%;
    column-gap: 50px;
    row-gap: 10px;
}
.columnFormDiv2{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:15% 18% 18%;
    column-gap: 0;
    row-gap: 10px;
}
.columnFormDiv2BigSize{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  15% 40% ;
    column-gap: 50px;
    row-gap: 10px;
}
.selectUf{
    display: grid;  
    justify-content: space-between;
    grid-template-columns: 70% 25%;
    column-gap: 5px;
}
.ouvidoriButton{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100% 1;
}

footer{
    color:blue;
}


@media (max-width: 1360px)
{  
    .gridFromOuvidoria1{  
        padding-bottom:40%;
    }
}
@media (max-width: 1280px)
{  
    .gridFromOuvidoria1{  
        grid-template-columns: 40% 40%;
        grid-template-rows: 100% 100%;
       
    }
}
@media (max-width: 960px)
{  
    .gridFromOuvidoria1{  
        padding-bottom:50%;
    }
}
@media (max-width: 780px)
{  
    .gridFromOuvidoria1{
        grid-template-columns: 60%;
        grid-template-rows: 40% 40% 30% 30%;
        row-gap: 0px;
        
    }
    .columnForm{
        padding-top: 10px;
        grid-template-rows: 20% 22% 22% 22%;

    }
    .columnFormDiv2{
        padding-top: 10px;
        grid-template-rows:20% 30% 30%;

    }
    .columnFormDiv2BigSize{
        grid-template-columns: 100%;
        grid-template-rows:  0% 80% ;
  
    }
    


}
