.boxDataBase{
    text-transform: uppercase;
    font-weight: 600;
    height: 10px;
    width: 55%;
    height: 50px;
    min-width: 222px;
    margin: 0 auto;
    margin-bottom: 25px ;
    border: 1px solid var(--cor-txt-navbar);
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.imgDatabase{
    transition: 2s;
    transform: rotate(90deg);
    margin-left: 10px;
}
.imgDatabaseOpen{
    transition: 2s;
    transform: rotate(270deg);
    margin-left: 10px;
}
.cardGrup{
    display: flex;
    flex-direction: column;
    max-width: 800px;
    /* width: 50%; */
    margin: 53px auto;
    border-left: 1px solid var(--cor-txt-navbar);
    transition-property: opacity, left, top, height;
    transition-duration: 3s, 5s;
   
}
.noneResult{
    margin: 0 auto;
    padding: 25px;
    text-transform: uppercase;
    padding-top: 1px;
    padding-right: 42px;
    color: #606569;
    /* min-width: 200px; */
    display: block;
    float: left;
}
.rotesPage{
    padding-left: 33px;
    color: #000000a6;
    margin: 0 auto;
    max-width: 1350px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.searchPageCenter{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 52px 0px;

}
.item{

    width: auto;
    margin-bottom: 46px;
    /* height: 50px; */
}
.dateCard{
    max-width: 300px;
    margin-left: 25px;
    position: relative;
    margin-bottom: 42px;
    color: #19232d;
    
   
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
   
    padding: 0 15px;
    line-height: 28px;
    border: 1px solid var(--cor-txt-navbar);
    margin-top: -14px;
    margin-bottom: 28px;
}
.dateCard:before {
    display: block;
    position: absolute;
    top: 13px;
    left: -26px;
    width: 25px;
    height: 1px;
    background: var(--cor-txt-navbar);;
    content: " ";
}
.dateText{

}
.imgBienios{
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 15px;
}
.titleCard{
    padding: 49px 38px 59px 27px;
    display: flex;
    align-items: center;
}
.titleText{
    text-transform: uppercase;
    padding-top: 0px;
    padding-left: 42px;
    color: #000000;
    min-width: 40px;
    display: block;
    float: left;
}

@media screen and (max-width: 1255px) {
    .titleCard{
        padding: 20px;
        padding-left: 50px;
    }
    .imgBienios{
        width: 130px;
        height: 130px;
        object-fit: cover;
        border-radius: 15px;
    }

  
    }

@media screen and (max-width: 800px) {
    .cardGrup{
        display: flex;
        flex-direction: column;
        max-width: 300px;
        /* width: 50%; */
        margin: 53px auto;
        border-left: 1px solid var(--cor-txt-navbar);
        transition-property: opacity, left, top, height;
        transition-duration: 3s, 5s;
       
    }
    .titleCard {
    padding: 49px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    }
    .titleText{
        padding-top: 20px;
    }
}