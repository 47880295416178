.container_filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 40px;
  margin-bottom: 10px;
  align-items: center;
  gap: 6px 12px;
  justify-content: space-between;
}

.label_filter {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label_filter input,
.label_filter select {
  padding: 8px 14px;
  border-radius: 6px;
  min-width: 280px;
  border: 1px solid #ccc;
}

.label_filter input {
  width: 340px;
}
