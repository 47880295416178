.containerLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10%;
  margin-bottom: 15%;
  width: 50px;
}

.containerLoaderFull {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loaderFull {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderText {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}
