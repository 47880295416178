.card {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 10px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  color: #424242;
  font-weight: 500;
}
