.boxInfor{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--esic-resultado-cor-txt);
    margin-bottom: 5px;
    margin-top: 35px;
    flex-direction: column;
    text-transform: uppercase;
}

.inforAlert{
    margin-bottom: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.iconeInfor{
    fill: var(--esic-resultado-cor-icone);
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.boxYear{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--esic-resultado-cor-txt);
    margin-bottom: 20px;
    margin-top: 20px;
}

.boxGraphic{
    max-width: 900px;
    max-height: 450px;
    margin: 0 auto;
    padding-bottom: 10px;
}
.graphic{
    max-width: 900px;
    max-height: 450px;
    margin: 0 auto;
    min-width: 494px;
    min-height: 247px;
}



@media screen and (max-width: 500px){
    .boxGraphic{
        overflow-x: auto;
    } 
}
