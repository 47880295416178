.gridPage{
    display: grid;  
    gap: 10px;
    row-gap: 35px;
    align-items: center;
    grid-template-areas: 
    'menu'
    'menu'
    'menu'
    'menu'
    'menu'
    'menu'
    'menu'
    'menu'
    'infoData'
    ;
}
#gridSalaEmpreendedor{
    grid-area: menu;
}
#ancoraRodape {
    grid-area: footer;
}




.empreendedor{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 255%;
    column-gap: 10px;
    justify-items: center;
    max-width: 1200px;
    justify-content: center;
    margin: 0 auto;
}
.cardEmpreendedor{
    background-color: var(--cor-fundo-sala-empreendedor);
    /* background-image: url('/img/sala-empreendedor-fundo (4).jpg'); */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    border-radius: 15px;
    
    display: flex;
    flex-direction: column;
    justify-content:  flex-end;
    
}
.cardEmpreendedor:hover{
    cursor:pointer;
    scale: 1.05;
    transition: 2s;
}

.title{
    margin-top: 10px;
    text-align: left;
    color: var(--cor-texto-card-sala-empreendedor);
    text-transform: uppercase;

}
.bodyCardText{
    background-color:  rgb(var(--RGB1-sombra-card-sala-empreendedor), var(--RGB2-sombra-card-sala-empreendedor), var(--RGB3-sombra-card-sala-empreendedor),0.428);
    border-radius:0px 0px 15px 15px ;
    padding: 10px;
    text-decoration:none ;
    height: 40%;
   
}
.bodyText{
    margin-top: 20px;
    text-align: justify;
    color: var(--cor-texto-card-sala-empreendedor);
    /* text-transform: uppercase; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Número máximo de linhas exibidas */
    -webkit-box-orient: vertical;

}


@media (max-width: 1274px)
{  
    .gridPage{
        display: grid;  
        gap: 10px;
        row-gap: 35px;
        align-items: center;
        grid-template-areas: 
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'infoData'
        ;
    }
.empreendedor{
     padding: 10px;
    grid-template-columns: 30% 30%;
    grid-template-rows: 100% 100%;
    row-gap: 10px;
    column-gap: 10px;
    
    
}
.bodyCardText{
 
    height: 48%;
   
}
  
}

@media (max-width: 1274px)
{  
    .gridPage{
        display: grid;  
        gap: 10px;
        row-gap: 35px;
        align-items: center;
        grid-template-areas: 
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'menu'
        'infoData'
        ;
    }

}
@media (max-width: 600px)
{
    
.empreendedor{
    padding: 15px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100% 100%;
    column-gap: 10px;
    row-gap: 10px;
}
.bodyText{
    text-align: left;
}
  
}