.containerInfor {
  max-width: 1201px;
  margin: 0 auto;
  padding: 50px 0;
  font-size: 0.9rem;
  /* display: grid; */
  /* grid-template-columns: 200px 200px 200px; */
}
.rowInfor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  padding: 10px 0;
  text-transform: uppercase;
}
.rowInforItem1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  padding: 14px 0;
  text-transform: uppercase;
  text-align: justify;
}
@media screen and (max-width: 1300px) {
  .containerInfor {
    padding: 50px 22px;
  }
}
@media screen and (max-width: 740px) {
  .rowInfor {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
    padding: 10px 0;
    font-size: 0.9em;
  }
  .rowInforItem1 {
    font-size: 0.9em;
  }
}
/* AVISOS HOME */

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.container_avisos_home {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.card_aviso {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 6px;
  position: relative;
  padding: 20px;
  color: #333;
  font-weight: 500;
}

.card_aviso:hover {
  border: 1px solid blue;
}

.card_link div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
}

.card_link:hover {
  color: #333;
}

.card_objeto {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.card_aviso:hover .card_objeto {
  color: blue;
}
